<template>
	<div class="angelfund">
		<div class="angelfund-container">
			<div class="angelfund-title">
				<span>彩票金白血病救助</span>
			</div>
			<div class="angelfundcontent">
				<div class="angeltitle">
					<h2>{{ list.title || "" }}</h2>
					<p>{{ list.tagKeywords || "" }}</p>
				</div>
				<div class="angeltips">
					<div class="tipscontent">
						<!-- “小天使基金”是中国红十字基金会在中央专项彩票公益金的支持下，广泛动员社会力量，
            为救助0~14周岁贫困家庭的白血病儿童而设立的专项公益基金。 -->
						“中央专项彩票公益金大病儿童救助项目”是由中国红十字总会负责，委托中国红十字基金会组织实施，协同全国各级红十字会和定点医疗机构执行，专项救助0-18周岁确诊为“白血病”的中国籍儿童。
					</div>
				</div>
				<div class="applyway">
					<h2>申请方式</h2>
					<div class="applycontent">
						<ul>
							<li v-for="(item, index) in applyways" :key="index">
								{{ item }}
							</li>
							<!-- <li>1、登录”中国红十字基金会“网站下载填写《小天使基金资助申请表》。</li>
                     <li>2、患儿及法定监护人户口簿（含首页）复印件。（如户口簿无法证实监护关系的，须提供患儿《出生医学证明》复印件。</li>
                     <li>3、患儿法定监护人身份证复印件。</li>
                     <li>4、近三个月内病情的诊断证明原件。</li>
                     <li>5、住院病案首页复印件（须加盖医院病案复印专用章）。</li>
                     <li>6、骨髓检查报告复印件。 以上资料齐全后提交至患儿户口所在地区县级红十字会</li> -->
						</ul>
					</div>
				</div>
				<Process :smallImage="list.smallImage"></Process>
				<Helpstandard :fund_standard="standardlsit"></Helpstandard>
			</div>
		</div>
	</div>
</template>

<script>
import Process from "./components/process";
import Helpstandard from "./components/helpstandard";
import apiUrls from "@api";
export default {
	components: {
		Process,
		Helpstandard,
	},
	name: "Angelfund",
	data() {
		return {
			applyways: [],
			list: {},
			standardlsit: [],
		};
	},
	created() {
		this.getangelfund();
	},
	methods: {
		getangelfund() {
			apiUrls.getangelfund({ node: "xtsjj" }).then((res) => {
				this.list = res.results.data;
				// console.log('小天使基金',this.list)
				this.applyways = res.results.data.apply_type.split(" ");
				this.standardlsit = [
					res.results.data.fund_standard_1,
					res.results.data.fund_standard_2,
					res.results.data.fund_standard_3,
				];
			});
		},
	},
};
</script>

<style lang="less" scoped>
.angelfund {
	background-color: #f5f5f5;
	.angelfund-container {
		width: 1200px;
		min-height: 400px;
		padding-bottom: 40px;
		margin: 0 auto;
		background-color: #f5f5f5;
		&::before {
			display: block;
			content: ".";
			clear: both;
			height: 0;
			overflow: hidden;
			visibility: hidden;
		}
		.angelfund-title {
			margin-top: 10px;
			font-size: 22px;
			color: #333333;
			font-weight: bold;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 16px;
			span {
				&:nth-child(1) {
					display: flex;
					&::before {
						content: "";
						display: block;
						height: 20px;
						width: 20px;
						background: url("../../../assets/img/title.png") no-repeat center
							center;
					}
				}
				&:nth-child(2) {
					color: #ababab;
					font-size: 17px;
				}
			}
		}
		.angelfundcontent {
			min-height: 1127px;
			overflow: hidden;
			background: url("../../../assets/img/angelbg.png") no-repeat center -290px,
				linear-gradient(0deg, #74c5ba, #62c1d6);
			box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
			.angeltitle {
				width: 550px;
				margin: 0 auto;
				text-align: center;
				color: #ffffff;
				margin-top: 62px;
				h2 {
					font-size: 27px;
					font-weight: bold;
					font-family: fantasy;
					letter-spacing: 5px;
					text-shadow: 0px 3px 7px rgba(74, 10, 10, 0.19);
					position: relative;
					margin-bottom: 6px;
					&::before {
						content: "";
						display: block;
						width: 43px;
						height: 68px;
						position: absolute;
						top: -23px;
						left: -40px;
						background: url("../../../assets/img/left-wing.png") no-repeat
							center center;
					}
					&::after {
						content: "";
						display: block;
						width: 43px;
						height: 68px;
						position: absolute;
						top: -23px;
						right: -40px;
						background: url("../../../assets/img/right-wing.png") no-repeat
							center center;
					}
				}
			}
			.angeltips {
				width: 1071px;
				min-height: 64px;
				margin: 0 auto;
				margin-top: 53px;
				padding: 19px 0;
				background: url("../../../assets/img/angeltips.png") no-repeat center
					center;
				.tipscontent {
					width: 691px;
					min-height: 45px;
					margin: 0 auto;
					text-align: center;
					font-size: 18px;
					font-weight: bold;
					color: #ffffff;
					position: relative;
					line-height: 24px;
					&::before {
						content: "";
						display: block;
						width: 80px;
						height: 97px;
						position: absolute;
						top: -50px;
						left: -80px;
						background: url("../../../assets/img/panda.png") no-repeat center
							center;
					}
				}
			}
			.applyway {
				width: 1003px;
				min-height: 203px;
				margin: 0 auto;
				margin-top: 39px;
				h2 {
					font-size: 24px;
					color: #ffffff;
					margin-bottom: 6px;
					margin-left: 29px;
				}
				.applycontent {
					width: 1003px;
					min-height: 203px;
					border: 2px dashed #ffffff;
					padding-bottom: 20px;
					border-radius: 24px;
					ul {
						width: 849px;
						height: 170px;
						// border: 1px solid #666666;
						margin: 17px 125px 16px 29px;
						li {
							line-height: 24px;
							color: #ffffff;
						}
					}
				}
			}
		}
	}
}
</style>
